import React from "react";
import Layout from "layout";
import {Container, Row, Col} from "react-grid-system";
import useDeviceSize from "libs/hooks/useDeviceSize";
import Seo from "common/seo";
import heroImage from "../images/eczema/a-bottle-of-eucerin-baby-eczema-relief-cream-wash-next-to-a-tube-of-eucerin-baby-eczema-relief-cream.png";
import img from "../images/eczema/a-bottle-of-eucerin-baby-eczema-relief-cream-body-wash.png";
import img1 from "../images/eczema/a-tube-of-eucerin-baby-eczema-relief-cream.png";
import imgOatmeal from "../images/icons/icons-of-colloidal-oatmeal-and-ceramide-np-in-red-circles.jpg";
import imgCeramide from "../images/icons/icons-of-colloidal-oatmeal-licochalcone-a-and-ceramide-np-in-red-circles.png";

import img4 from "../images/eczema/skin-with-red-patches-shown-on-the-back-of-a-baby-above-it-s-diaper.jpg";
import img5 from "../images/eczema/a-doctor-talling-to-a-female-patient-and-her-daughter.jpg";
import img6 from "../images/eczema/image-of-a-ph-scale.webp";
import img7 from "../images/eczema/a-mom-hugging-her-son-wrapped-in-a-towel-in-the-bathroom.jpg";
import img8 from "../images/eczema/Skin-graphic.jpg";
import img9 from "../images/eczema/someone-applying-lotion-to-the-top-of-a-hand.jpg";
import {TypeA, TypeB} from "common/callouts";

import icon1 from "../images/icons/blue-bubble-graphic.png";
import icon2 from "../images/icons/purple-bottle-graphic.png";
import icon3 from "../images/icons/pink-clock-graphic.png";
import icon4 from "../images/icons/yellow-soap-graphic.png";
import icon5 from "../images/icons/blue-sponge-graphic.png";
import icon6 from "../images/icons/grey-smoke-graphic.png";

import {
    ProductTypeA,
    ProductTypeB,
    ProductTypeC,
} from "common/product-details";

import "./index.scss";

function HomePage() {
    const deviceSizes = useDeviceSize();

    return (
        <Layout pageClass="home">
            <Seo pageTitle="Eczema Relief" pageDescription="Learn about Eucerin's therapeutic solutions for eczema"/>


            {/* HERO SECTION */}
            <section className="fluid-section red-gradient-bg hero-banner">
                <div className="inner-centered-container">
                    <div className="hero-container">
                        <Row>
                            <Col md={12} lg={8} className={deviceSizes?.smUp ? "" : ""} order={{xs: 2, sm: 2, md: 2, lg: 1}}>
                                <ProductTypeC productAlt="A tube of Eucerin Eczema Relief Cream and a bottle of Eczema Relief Cream body wash">
                                    <div className="functions">
                                        <h4>Eucerin<sup>&reg;</sup> Baby Eczema Relief Regimen:</h4>
                                        <h1>Pediatric skin<br/>pHriendly</h1>
                                        <p>
										<span className="mid-content">A pH-optimized regimen for eczema-prone skin formulated to be gentle enough for everyday use in children as young as 3 months.</span>
                                        </p>
                                        <ul className="checkmarks white">
                                            <li>
                                                <span>S T E R O I D - F R E E</span>
                                            </li>
                                            <li>
                                                <span>F R A G R A N C E - F R E E</span>
                                            </li>
                                            <li>
                                                <span>D Y E - F R E E</span>
                                            </li>
                                        </ul>
                                        <ul className="checkmarks white">
                                            <li>
                                                <span>P A R A B E N - F R E E</span>
                                            </li>
                                            <li>
                                                <span>N O N - C O M E D O G E N I C</span>
                                            </li>
                                        </ul>
                                    </div>
                                </ProductTypeC>
                            </Col>
                            <Col md={12} lg={4} className={deviceSizes?.smUp ? "" : ""} order={{xs: 1, sm: 1, md: 1, lg: 2}}>
                                <div className="cout-products">
                                    <img
                                        src={heroImage}
                                        alt="A bottle of Eucerin Baby Eczema Relief Cream & Wash next to a tube of Eucerin Baby Eczema Relief Cream"
                                        className="hero-product"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>


            {/* LINK-BUTTON SECTION 1 */}
            <section className="inner-centered-container callout-with-links-bullets">
                <Container fluid>
                    <Row>
                        <Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <img src={img} alt="A bottle of Eucerin Baby Eczema Relief Cream & Body Wash" className=""/>
                        </Col>
                        <Col sm={12} md={9} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <h2>
                                Eucerin<sup>&reg;</sup> Baby Eczema Relief Cream
                                 <span class="fbreak">& Body Wash</span>
                            </h2>
                            <div className="links-wrap">
                                <span className="font-bold red-text">Buy now at:</span>
                                <br/>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.amazon.com/Eucerin-Eczema-Relief-Cleanser-Eczema-prone/dp/B08BB2VV68/ref=sr_1_1?crid=36RLEJG0ZIQI5&keywords=Eucerin+baby+eczema+relief+cream+and+body+wash&qid=1665432319&rdc=1&sprefix=eucerin+baby+eczema+relief+cream+and+body+wash%2Caps%2C109&sr=8-1" target="_blank">Amazon</a>
                                    </li>
                                    {/*<li>
                                        <a rel="noopener noreferrer" href="https://www.walmart.com/ip/Eucerin-Eczema-Relief-Cream-Body-Wash-Eczema-Body-Wash-13-5-Fl-Oz-Bottle/274424851?wmlspartner=wlpa&selectedSellerId=0&wl13=2907&adid=22222222277274424851_117755028669_12420145346&wmlspartner=wmtlabs&wl0=&wl1=g&wl2=c&wl3=501107745824&wl4=pla-293946777986&wl5=9011884&wl6=&wl7=&wl8=&wl9=pla&wl10=8175035&wl11=local&wl12=274424851&wl13=2907&veh=sem_LIA&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMTumfIwlfXJTywNqNOcwHy4E-_xnLcg2eaqpAeFmT4b5UPL2W1WdFUaAmQLEALw_wcB&gclsrc=aw.ds" target="_blank">Walmart</a>
                                    </li>*/}
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.target.com/p/eucerin-baby-eczema-relief-cream-body-wash-gentle-cleanser-13-5-fl-oz/-/A-83386692#lnk=sametab" target="_blank">Target</a>
                                    </li>
                                </ul>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.cvs.com/shop/eucerin-baby-eczema-relief-cream-body-wash-13-5-oz-prodid-2750049" target="_blank">CVS</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/eucerin-baby-eczema-cream-body-wash/ID=300421031-product" target="_blank">Walgreens</a>
                                    </li>
                                </ul>

                                <ul className="dblarrows red">
                                    <li>
										<span>
											Soap-free, non-foaming, non-drying, non-irritating
										</span>
                                    </li>
                                    <li>
										<span>
											<span className="font-bold">Low pH formula</span> with ceramide and colloidal oatmeal (skin protectant) to help protect and soothe skin during bathing
										</span>
                                    </li>
                                </ul>

                                <div className="icon-content">
                                    <span className="icon-text">ENRICHED WITH:</span>
                                    <div class="img-wrapper">
                                        <img
                                            src={imgOatmeal}
                                            alt="Icons of Colloidal oatmeal and Ceramide NP in red circles"
                                            className=""
                                        />
                                        {/*<span className="plus">+</span>
                                        <img
                                            src={imgCeramide}
                                            alt="Circle icon representing Ceramide NP"
                                            className=""
                                        />*/}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="inner-centered-container">
                <hr></hr>
            </section>

            {/* LINK-BUTTON SECTION 2 */}
            <section className="inner-centered-container callout-with-links-bullets">
                <Container fluid>
                    <Row>
                        <Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <img src={img1} alt="A tube of Eucerin Baby Eczema Relief Cream" className=""/>
                        </Col>
                        <Col sm={12} md={9} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <h2>
                                Eucerin<sup>&reg;</sup> Baby Eczema Relief Cream
                            </h2>
                            <div className="links-wrap">
                                <span className="font-bold red-text">Buy now at:</span>
                                <br/>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.amazon.com/Eucerin-Baby-Eczema-Relief-Cream/dp/B086161PTN/ref=sr_1_2?crid=2FKWR1YJ7BR7W&keywords=Eucerin+baby+eczema+relief+cream&qid=1665433129&qu=eyJxc2MiOiIyLjg5IiwicXNhIjoiMi42OSIsInFzcCI6IjIuNTEifQ%3D%3D&rdc=1&sprefix=eucerin+baby+eczema+relief+cream%2Caps%2C114&sr=8-2" target="_blank">Amazon</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walmart.com/ip/Eucerin-Baby-Eczema-Relief-Body-Cream-Fragrance-Free-Baby-Eczema-Cream-8-Oz-Tube/827858556" target="_blank">Walmart</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.target.com/p/eucerin-baby-eczema-relief-body-cream-8oz/-/A-85638361#lnk=sametab" target="_blank">Target</a>
                                    </li>
                                </ul>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.cvs.com/shop/eucerin-baby-eczema-relief-creme-8-oz-prodid-2750064" target="_blank">CVS</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/eucerin-baby-eczema-relief-creme/ID=300421541-product" target="_blank">Walgreens</a>
                                    </li>
                                </ul>

                                <ul className="dblarrows red">
                                    <li>
										<span>
											<span className="font-bold">Clinically proven</span> to help relieve itch and fortify eczema-prone skin
										</span>
                                    </li>
                                    <li>
										<span>
                                            Formulated with ceramide, colloidal oatmeal (skin protectant), and licorice root extract
											<span className="font-bold"> for use in children as young as 3 months</span>
										</span>
                                    </li>
                                    <li>
										<span>
											<span className="font-bold">4 out of 5 children</span>{" "}
                                            remained <nobr>flare-free</nobr> for 6 months with daily use <sup>1</sup>
										</span>
                                    </li>
                                </ul>

                                <div className="icon-content">
                                    <span className="icon-text">ENRICHED WITH:</span>
                                    <div class="img-wrapper">
                                        {/*<img
                                            src={imgOatmeal}
                                            alt="Circle icon representing Licochalcone"
                                            className=""
                                        />
                                        <span className="plus">+</span>
                                        <img
                                            src={imgLicochalcone}
                                            alt="Circle icon representing Ceramide NP"
                                            className=""
                                        />
                                        <span className="plus">+</span>*/}
                                        <img
                                            src={imgCeramide}
                                            alt="Icons of Colloidal oatmeal, Licochalcone A and Ceramide NP in red circles"
                                            className=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="disclaimer-text">
                            <p><span className="font-bold">Reference: 1.</span> Weber TM, Samarin F, Babcock MJ, Filbry A, Rippke F. Steroid-free over-the-counter eczema skin care formulations reduce risk of flare, prolong time to flare, and reduce eczema symptoms in pediatric subjects with atopic dermatitis. <i>J Drugs Dermatol.</i> 2015;14(5):478-485.</p>
                        </div>
                    </Row>
                </Container>
            </section>

            {/* TREATMENT GOAL SECTION */}
            <div className="inner-body-content">
                <section className="inner-centered-container treatment">
                    <ProductTypeA
                        categoryBgColor="#A70531"
                        categoryName="Treatment Goals"
                        categoryFontColor="#ffffff"
                        productName="Treatment goals for eczema"
                        productAlt="Skin with red patches shown on the back of a baby above it's diaper"
                        productImage={img4}
                    >
                        <div className="functions">
                            <p>
                                Your child’s pediatrician will review the treatment goals for their specific <nobr>eczema-prone</nobr> skin, but they often include:
                            </p>
                            <ul className="dblarrows red">
                                <li>Reducing the frequency and number of flares</li>
                                <li>
                                    Relieving symptoms (redness, dryness, itching, irritation)
                                </li>
                                <li>Identifying and managing triggers</li>
                                <li>Using products that are <nobr>pH-optimized</nobr> to skin</li>
                                <li>
                                    Improving hydration and maintaining moisture of the skin
                                </li>
                            </ul>
                        </div>
                    </ProductTypeA>
                </section>
            </div>

            {/* QUESTION TO ASK */}

            <div className="inner-body-content grey-section">
                <section className="inner-centered-container">
                    <ProductTypeB
                        categoryBgColor="#A70531"
                        categoryName="QUESTIONS TO ASK"
                        categoryFontColor="#ffffff"
                        productName="Questions for the pediatrician"
                        productAlt="A Doctor talking to a female patient and her daughter"
                        productImage={img5}
                    >
                        <div className="functions">
                            <ul className="dblarrows red">
                                <li>My child is still having flares. What can we do to reduce them?</li>
                                <li>What can help their itching/irritation?</li>
                                <li>What moisturizer should they use to treat their dry skin?</li>
                                <li>How can I identify and help eliminate or reduce their triggers?</li>
                                <li>Can you help me understand why skin pH is important for people with eczema?</li>
                            </ul>
                        </div>
                    </ProductTypeB>
                </section>
            </div>

            {/* PH SCALE */}
            <section className="callouts-container gradient-bg section-ph-scale">
                <div className="inner-centered-container">
                    <Row>
                        <Col sm={12} md={12}>
                            <TypeA
                                className="center-v"
                                headBgColor="#A70531"
                                headName="pH AND ECZEMA"
                                headFontColor="#ffffff"
                                head1="Skin of eczema patients has a higher pH (is less acidic) than healthy skin."
                                prodLabel="Skin pH and its impact on eczema-prone skin"
                                phContent="The pH range for healthy skin is slightly acidic:"
                                phNumber="4-6"
                                prodImage={img6}
                                productAlt="Image of a pH scale"
                            >
                                <p className="reg">
                                    An acidic skin pH can help your child's skin support a healthy skin moisture barrier and resist the growth of harmful bacteria.
                                </p>
                            </TypeA>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* HEALTHY HABITS */}

            <section className="callout-container fluid-section darkgray-gradient-bg healthy-habits full-with-section">
                <div className="inner-container">
                    <Row>
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""} order={{xs: 2, md: 1}}>
                            <ProductTypeC
                                categoryBgColor="#A70531"
                                categoryName="Healthy Habits"
                                categoryFontColor="#ffffff"
                                productName="Healthy habits to pHortify <span className='fbreak'>your <nobr>eczema-prone skin</nobr></span"
                            >
                                <div className="functions">
                                    <h2>
                                        Changing your bathing routine can help lower your skin’s
                                        pH
                                    </h2>
                                    <ul className="dblarrows white">
                                        <li>
                                            Typical bar soap can have a pH of <nobr>9-10</nobr>. Washing with alkaline bar soap can raise the pH of skin, lasting for more than an hour
                                        </li>
                                    </ul>
                                </div>
                            </ProductTypeC>
                        </Col>
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""} order={{xs: 1, md: 2}}>
                            <div className="cout-img">
                                <img
                                    src={img7}
                                    alt="A mom hugging her son wrapped in a towel in the bathroom"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* THINGS TO DO SECTION */}

            <section className="callout-container inner-centered-container">
                <div className="inner-body-content">
                    <Row>
                        <Col sm={12} md={12} order={{xs: 2, md: 1}} className="">
                            <TypeB
                                className="icon-wrap"
                                headName="Things to do:"
                                headFontColor="#A70531"
                                quoteContent="Identify your child’s triggers: allergens, climate, external irritants, scratching, or a genetic predisposition.">
                                <Container fluid>
                                    <Row>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                                <img src={icon1} alt="Blue bubble graphic"></img>
                                            </div>
                                            <p>
                                                Limit bath time to 5-10 minutes
                                                using warm (not hot) water at
                                                least once a day
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                            <img src={icon2} alt="Purple bottle graphic"></img>
                                            </div>
                                            <p>
                                                Use products formulated for
                                                sensitive skin (low pH, no
                                                fragrances, no dyes)
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                            <img src={icon3} alt="Pink clock graphic"></img>
                                            </div>
                                            <p>
                                                Moisturize every day to help keep
                                                developing, eczema-prone skin
                                                hydrated and healthy-looking
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                            </TypeB>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* THINGS NOT TO USE  SECTION */}

            <section className="callout-container inner-centered-container my-75 icon-section">
                <div className=" inner-body-content">
                    <Row>
                        <Col sm={12} md={12} order={{xs: 2, md: 1}} className="">
                            <TypeB
                                className="icon-wrap"
                                headName="THINGS TO AVOID:"
                                headFontColor="#A70531"
                                quoteContent="Don’t exacerbate your child’s eczema. Reduce their opportunity to scratch by trimming their nails or having them sleep in cotton gloves.">
                                <Container fluid>
                                    <Row>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                            <img src={icon4} alt="Yellow soap graphic"></img>
                                            </div>
                                            <p>
                                                Harsh chemicals in
                                                cleansers, soaps, sanitizers,
                                                and detergents
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                            <img src={icon5} alt="Blue sponge graphic"></img>
                                            </div>
                                            <p>
                                                Abrasive, synthetic fabrics
                                                and scratchy wool
                                            </p>
                                        </Col>
                                        <Col sm={12} md={4} className="mobile">
                                            <div className="icon">
                                            <img src={icon6} alt="Grey smoke graphic"></img>
                                            </div>
                                            <p>Exposing your child to
                                                secondhand smoke</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </TypeB>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* SURFACE  ON SKIN */}

            <section className="callout-container fluid-section darkgray-gradient-bg full-with-section skin-protective-layer">
                <div className="inner-container">
                    <Row>
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""} order={{xs: 2, md: 1}}>
                            <ProductTypeC
                                categoryFontColor="#ffffff"
                                productName="The skin's surface has a protective layer called the acid mantle">
                                <div className="functions">
                                    <h2>
                                        This protective barrier:
                                    </h2>
                                    <ul className="dblarrows white">
                                        <li>Helps the skin maintain its moisture</li>
                                        <li>
                                            Acts as a barrier to harmful bacteria and other
                                            elements that may penetrate the skin
                                        </li>
                                    </ul>
                                </div>
                            </ProductTypeC>
                        </Col>
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "no-pr" : ""} order={{xs: 1, md: 2}}>
                            <div className="cout-img">
                                <img
                                    src={img9}
                                    alt="Someone applying lotion to the top of a hand"
                                    className="image"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* ECZEMA PRONE SKIN */}
            <div className="inner-body-content">
                <section className="inner-centered-container eczema-prone">
                    <ProductTypeB
                        categoryBgColor="#fff"
                        categoryFontColor="#ffffff"
                        productName="Eczema-prone skin has a damaged skin barrier that can flare and result in a red, scaly, itchy rash"
                        productAlt="Skin graphic"
                        productImage={img8}
                    >
                        <div className="functions">
                            <p>
                                A damaged protective acid mantle can allow harmful bacteria and other elements to enter the damaged skin. It is more difficult to keep the skin moisturized.
                            </p>
                        </div>
                    </ProductTypeB>
                </section>
            </div>

            <section className="inner-centered-container my-75">
                <hr></hr>
            </section>

        </Layout>
    );
}

export default HomePage;
